import EmblaCarousel from "embla-carousel";
import AutoHeight from "embla-carousel-auto-height";
import ClassNames from "embla-carousel-class-names";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Logo slider");

	if (document.querySelector(".logo-slider.embla")) {
		logoSlider();
	}
}

function logoSlider() {
	const wrap = document.querySelector(".logo-slider.embla");
	const viewPort = wrap.querySelector(".embla__viewport");
	const numSlides = wrap.getAttribute("data-num-slides");
	const prevBtn = wrap.querySelector(".embla__button--prev");
	const nextBtn = wrap.querySelector(".embla__button--next");
	const dots = wrap.querySelector(".embla__dot_navigation");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: numSlides > 1,
			draggable: false,
			loop: true
		},
		[ClassNames()]
	);

	// Add disabled class if slider has 1 slide
	if (numSlides === "1") {
		wrap.classList.add("disabled");
	}

	// Previous/next navigation
	// const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
	// 	embla,
	// 	prevBtn,
	// 	nextBtn
	// );

	// const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
	// 	embla,
	// 	prevBtn,
	// 	nextBtn
	// );

	// Dot navigation
	// const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
	// 	embla,
	// 	dots
	// );

	// Auto scroll
	const rafId = {};

	const animate = () => {
		if (!embla || !rafId.current) return;

		const engine = embla.internalEngine();
		engine.location.add(-0.75);
		engine.target.set(engine.location);
		engine.scrollLooper.loop(-1);
		engine.slideLooper.loop();
		engine.translate.to(engine.location);
		rafId.current = requestAnimationFrame(animate);
	};

	const startAutoScroll = () => {
		rafId.current = requestAnimationFrame(animate);
	};

	const stopAutoScroll = () => {
		rafId.current = cancelAnimationFrame(rafId.current) || 0;
	};

	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
	embla.on("pointerDown", stopAutoScroll);
	embla.on("settle", startAutoScroll);
	startAutoScroll();
}

export default { init };
