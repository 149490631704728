import ada from "./modules/ada";
import astra from "./modules/astra";
import dialogContact from "./modules/dialogs/contact";
import dialogRequestADemo from "./modules/dialogs/request-a-demo";
import dialogVideo from "./modules/dialogs/video";
import pagination from "./modules/pagination";
import sliderBanner from "./modules/sliders/banner";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderSolutions from "./modules/sliders/solutions";
import sliderTestimonial from "./modules/sliders/testimonial";
import test from "./modules/test";
import coreWebVitals from "./modules/tests/core-web-vitals";
import utils from "./modules/utils";

// Utils
// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element, .site-footer-below-section-1"
	)
);

// Video fade
utils.e10kVideoFadeIn(document.querySelector(".background-video"));

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
// astra.init();

// Pagination
// pagination.init();

// Dialogs
// dialogContact.init();
dialogRequestADemo.init();
dialogVideo.init();

// Sliders
sliderBanner.init();
sliderGrid.init();
sliderLogo.init();
sliderSolutions.init();
sliderTestimonial.init();

// Tests
// test.log();
coreWebVitals.init();
