import EmblaCarousel from "embla-carousel";
import AutoHeight from "embla-carousel-auto-height";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addThumbBtnsClickHandlers,
	addTogglePrevNextBtnsActive,
	addToggleThumbBtnsActive
} from "./navigation";

function init() {
	// console.log("Solutions slider");

	const solutionsSliderEls = document.querySelectorAll(
		".block-solutions .embla"
	);

	if (solutionsSliderEls) {
		solutionsSliderEls.forEach((el) => {
			const numSlides = el.getAttribute("data-num-slides");
			solutionsSlider(el, numSlides);
		});
	}
}

function solutionsSlider(el, numSlides) {
	const wrap = el.querySelector(".embla__content_wrapper");
	const viewPort = wrap.querySelector(".embla__viewport");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: numSlides > 1,
			// align: "start",
			// containScroll: "keepSnaps",
			// duration: 20,
			dragFree: false,
			loop: true
		},
		[ClassNames(), AutoHeight()]
	);

	// Add disabled class if slider has 1 slide
	if (numSlides === "1") {
		wrap.classList.add("disabled");
	}

	// Thumbs
	const thumbsWrap = el.querySelector(".embla__navigation_wrapper");
	const thumbsViewPort = thumbsWrap.querySelector(".embla-thumbs__viewport");
	const emblaThumbs = EmblaCarousel(thumbsViewPort, {
		active: false
	});

	// Thumb buttons
	const removeThumbBtnsClickHandlers = addThumbBtnsClickHandlers(
		embla,
		emblaThumbs
	);

	const removeToggleThumbBtnsActive = addToggleThumbBtnsActive(
		embla,
		emblaThumbs
	);

	// Autoplay
	const auto = autoPlay(embla, viewPort, 15000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
	embla.on("destroy", removeThumbBtnsClickHandlers);
	embla.on("destroy", removeToggleThumbBtnsActive);
	emblaThumbs.on("destroy", removeThumbBtnsClickHandlers);
	emblaThumbs.on("destroy", removeToggleThumbBtnsActive);

	// Reinitialize slider after 1000ms to fix bug with autoheight
	// setTimeout(embla.reInit, 1000);
}

export default { init };
